@value colors: "../../../../../../../components/library/style/colors.css";
@value color-error-light, color-error-dark, color-success-light, color-success-dark, color-gray, color-gray-light, color-text-subtle from colors;

.error {
    background-color: color-error-light;
    color: color-error-dark;
}

.success {
    background-color: color-success-light;
    color: color-success-dark;
}

.status {
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 16px;
    width: fit-content;
}

.status .title {
    margin: 0;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.status .icon {
    margin-right: 8px;
}

.status .description {
    margin: 0;
}

.label {
    margin-bottom: 4px;
}

.background {
    position: fixed;
    top: 0;
    right: 0;

    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.75);
}

.modal {
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

    padding: 16px;
    border-radius: 5px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonGroup button {
    width: 49%;
}
.custom {
    background-color: color-gray-light;
    color: color-text-subtle;
    transition: .3s;
}

.custom:hover {
    background-color: color-gray;
}

.title {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.description {
    margin-top: 4px;
}
