@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-error-light, color-error-dark, color-success-light, color-success-dark, color-error-bg, color-error-dark, color-purple-bg, color-gray-light, color-gray, color-purple from colors;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0,0,0,0.75);
}

.list {
    max-height: 40vh;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}

.error {
    background-color: color-error-bg;
    color: color-error-dark;
}

.list li {
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
}

.popup {
    background-color: white;
    width: 50%;
    min-width: 320px;
    max-height: 90vh;
    padding: 16px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: flex-end;

    margin-top: 16px;
}

.footer button + button {
    margin-left: 8px;
}

.header h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    color: color-text-subtle;
    margin-bottom: 16px;
}

.input {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto;
    color: color-text-subtle;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}

.selected {
    background-color: color-purple-bg;
    color: color-purple;
}

.create {
    margin-top: 8px;
    margin-bottom: 0;
}

.create span {
    color: color-purple;
    font-weight: 500;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.default {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.default label {
    margin-right: 8px;
}

.default p {
    color: color-text-subtle;
    margin: 0;
}

.bold {
    font-weight: 500;
}

.cancel {
    color: color-text-subtle;
    border: 1px solid color-gray;
    background: none;
}

.cancel:hover {
    background-color: color-gray-light;
}

.success {
    background-color: color-success-light;
    color: color-success-dark;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.error {
    background-color: color-error-light;
    color: color-error-dark;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 4px;
}