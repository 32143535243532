@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
/* Primary Color */

/* Accent Color */

/* Neutral color */

/* Feedback color */

/* Typography */

/* Border */

/* Forms */

/* Button */

.Loading_loadingContainer__1DQM9 {
  width: 100%;
  height: 50vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* Loader css come from: https://loading.io/css/ */

.Loading_loading__1_-fF {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Loading_loading__1_-fF div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
  -webkit-animation: Loading_loading__1_-fF 1.2s linear infinite;
          animation: Loading_loading__1_-fF 1.2s linear infinite;
}
.Loading_loading__1_-fF div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.Loading_loading__1_-fF div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.Loading_loading__1_-fF div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.Loading_loading__1_-fF div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.Loading_loading__1_-fF div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.Loading_loading__1_-fF div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.Loading_loading__1_-fF div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.Loading_loading__1_-fF div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.Loading_loading__1_-fF div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.Loading_loading__1_-fF div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.Loading_loading__1_-fF div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.Loading_loading__1_-fF div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@-webkit-keyframes Loading_loading__1_-fF {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes Loading_loading__1_-fF {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.Button_button__2PPIh {
  text-decoration: none;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px 36px;
  cursor: pointer;
  outline: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: .3s;
  font-weight: medium;
}

.Button_fill__NYUad {
  border: none;
}

.Button_primary__3v-_2.Button_fill__NYUad {
  background-color: #DE330D;
  color: white;
}

.Button_primary__3v-_2.Button_fill__NYUad:hover {
  background-color: #A32003;
}

.Button_primary__3v-_2.Button_fill__NYUad:focus {
  background-color: #F24C27;
}

.Button_secondary__1Sjmb.Button_fill__NYUad {
  background-color: #4F67F3;
  color: white;
}

.Button_secondary__1Sjmb.Button_fill__NYUad:hover {
  background-color: #404FA1;
  color: white;
}

.Button_secondary__1Sjmb.Button_fill__NYUad:focus {
  background-color: #7D8FF5;
  color: white;
}

.Button_disabled__1agf5.Button_fill__NYUad {
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.Button_primary__3v-_2.Button_outline__1qgYM {
  border: 2px solid #DE330D;
  color: #DE330D;
  background: none;
}

.Button_primary__3v-_2.Button_outline__1qgYM:hover {
  border: 2px solid #A32003;
  color: #A32003;
  background-color: #F8D7DA;
}

.Button_primary__3v-_2.Button_outline__1qgYM:focus {
  border: 2px solid #F24C27;
  color: #DE330D;
  background-color: #F8D7DA;
}

.Button_secondary__1Sjmb.Button_outline__1qgYM {
  border: 2px solid #4F67F3;
  color: #4F67F3;
  background: none;
}

.Button_secondary__1Sjmb.Button_outline__1qgYM:hover {
  border: 2px solid #404FA1;
  color: #404FA1;
  background-color: #EBEEFF;
}

.Button_secondary__1Sjmb.Button_outline__1qgYM:focus {
  border: 2px solid #7D8FF5;
  color: #4F67F3;
  background-color: #EBEEFF;
}

.Button_ghost__2J2W2 {
  border: none;
  background: none;
}

.Button_primary__3v-_2.Button_ghost__2J2W2 {
  color: #DE330D;
  padding: 0;
}

.Button_primary__3v-_2.Button_ghost__2J2W2:hover {
  color: #A32003;
}

.Button_primary__3v-_2.Button_ghost__2J2W2:focus {
  color: #F24C27;
}

.Button_secondary__1Sjmb.Button_ghost__2J2W2 {
  color: #4F67F3;
  padding: 0;
}

.Button_secondary__1Sjmb.Button_ghost__2J2W2:hover {
  color: #404FA1;
}

.Button_secondary__1Sjmb.Button_ghost__2J2W2:focus {
  color: #7D8FF5;
}

.Button_disabled__1agf5.Button_ghost__2J2W2 {
  color: #C0BFCF;
}

.Button_icon__2shRk {
  margin-right: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Button_small__3SXl_ {
  height: 38px;
  min-width: 88px;
  font-size: 16px;
}

.Button_medium__wfjdp {
  height: 48px;
  min-width: 112px;
  font-size: 18px;
}

.Button_large__3CZbZ {
  height: 72px;
  min-width: 150px;
  font-size: 18px;
}

.Navigation_navigation__2BhFP {
    grid-area: header;
    width: 100%;
    height: 65px;
    padding: 0 24px;
    box-sizing: border-box;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;

    background-color: white;
    border-bottom: 1px solid #E5E5E5;
}

.Navigation_navigation__2BhFP p {
    font-size: 24px;
}

.Navigation_active__DW3WT {
    color: #4F67F3;
}

.Navigation_navigation__2BhFP button {
    padding: 0;
    min-width: 0;
}

.Navigation_navigation__2BhFP p {
    margin-right: 32px;
}

.Navigation_navigation__2BhFP button {
    margin-right: 32px;
}

.Navigation_navigation__2BhFP .Navigation_logOut__30qbI {
    justify-self: flex-end;
    color: #72757E;
    border: 1px solid #CCCCCC;
    padding: 0 16px;
}

.Navigation_navigation__2BhFP .Navigation_logOut__30qbI:hover {
    background-color: #EFEFEF;
    border-color: #EFEFEF;
}
.shared_logo__1h2eY {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    text-align: center;
    margin: 0;
}
.UsersList_usersList__2nt7K {
    grid-area: sidebar;
    box-shadow: 4px 0 25px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 65px);
    background-color: white;
}

.UsersList_inputWrapper__6B2UY {
    border-bottom: 1px solid #E5E5E5;

    height: 75px;
    padding: 0 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.UsersList_searchbar__tsiIL {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    color: #72757E;
}

.UsersList_input__2Jnfz {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto, sans-serif;
    color: #72757E;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}

.UsersList_list__NYQL5 {
    list-style: none;
    padding: 0;
    margin: 0;
    height: calc(100% - 75px);
    overflow-y: scroll;
    background-color: white;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
}

.UsersList_list__NYQL5::-webkit-scrollbar {
    width: 5px;  /* for vertical scrollbars */
}

.UsersList_list__NYQL5::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.UsersList_item__3ZxJ8 {
    padding: 0 24px;
    border-bottom: 1px solid #E5E5E5;
    height: 82px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    transition: .3s;
}

.UsersList_item__3ZxJ8:hover, .UsersList_item__3ZxJ8:focus, .UsersList_selected__3IXTx {
    background-color: #F4F4F4;
    cursor: pointer;
}

.UsersList_title__hcw4M {
    font-weight: 500;
    margin: 0;
}

.UsersList_description__3r1YF {
    color: #72757E;
    margin: 0;
}

.UsersList_users__BHtrM {
    padding: 8px 24px;
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
}

.UsersList_icon__29HEP {
    font-size: 16px !important;
    margin-left: 4px;
}

.UsersList_icon__29HEP.UsersList_green__2StV0 {
    color: #0EDC97;
}

.UsersList_icon__29HEP.UsersList_orange__2Uuiy {
    color: darkorange;
}

.UsersList_icon__29HEP.UsersList_red__2qfGK {
    color: #DE330D;
}

.UserDescription_userDescription__S4qtW {
    min-height: calc(100vh - 65px);
    box-sizing: border-box;
    padding: 24px;
    grid-area: main;
    background-color: #F4F4F4;
}

.UserDescription_main__zwbdG {
    background-color: white;
    height: 100%;
}

.UserDescription_title__12_rc {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;

    margin: 0;
}

.UserDescription_header__B2psB {
    padding: 24px;
    height: 12%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #E5E5E5;
}

.UserDescription_active__1g6_S {
    color: #0EDC97;
    font-weight: 500;
    margin: 0;
}


.UserDescription_accountState__VhiVO {
    color: #0EDC97;
    font-weight: 500;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.UserDescription_accountState__VhiVO p {
    margin: 0;
}

.UserDescription_accountState__VhiVO .UserDescription_dot__VA6Rd {
    height: 9px;
    width: 9px;
    border-radius: 9px;
    margin-right: 9px;
    background-color: #0EDC97;
}

.UserDescription_section__7uLj1 {
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;
}

.UserDescription_section__7uLj1 header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.UserDescription_section__7uLj1 header h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.UserDescription_form__30FxC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.UserDescription_form__30FxC > div {
    width: 320px;
}

.UserDescription_buttonGroup__3gvau {
    display: -webkit-flex;
    display: flex;
}

.UserDescription_buttonGroup__3gvau button + button {
    margin-left: 24px;
}

.Information_row__3g0Uy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Information_row__3g0Uy button + button {
    margin-left: 16px;
}

.Input_input__1C7iz {
  height: 42px;
  width: 100%;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}


.Input_textarea__1EWeK {
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
}

.Input_input__1C7iz .Input_ant-tooltip__mmkYw {
  display: none;
}

.Input_input__1C7iz input {
  all: unset;
  border: none;
  height: 40px;
  width: 98%;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.Input_textarea__1EWeK textarea {
  border: none;
  height: 250px;
  width: 98%;
  margin: auto;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  resize: none;
}

.Input_input__1C7iz:focus-within, .Input_inputActive__34Z9o, .Input_input__1C7iz:hover {
  border-color: #526AF2;
  outline: none;
  transition: .3s;
  cursor: pointer;
}

.Input_input__1C7iz:focus-within {
  box-shadow: 0px 0px 6px #b1bdff;
}

.Input_label__3WviC {
  margin-bottom: 8px;
  text-align: left;
  font-weight: 500;
}

.Input_hint__2cxRf {
  font-size: 12px;
  margin-top: 4px;
}

@media screen and (max-width: 450px) {
  .Input_input__1C7iz {
    height: unset;
    min-height: 42px;
  }
}

.InputText_label__1aDQV {
  margin-bottom: 8px;
  text-align: left;
  font-weight: 500;
}

.InputText_hint__1PMle {
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

.InputText_required__2Os3y {
  color: #4F67F3;
}

.InputText_inputSuccess__H-8fJ {
  border: 1px solid #49AD40;
}

.InputText_inputSuccess__H-8fJ:focus-within {
  box-shadow: none;
}

.InputText_hintSuccess__1rjOk {
  color: #49AD40;
}

.InputText_iconSuccess__4KNJU {
  color: #49AD40;
  margin-right: 16px;
}

.InputText_inputError__260xo {
  border: 1px solid #DE330D;
}

.InputText_inputError__260xo:focus-within {
  box-shadow: none;
}

.InputText_hintError__2oUK7 {
  color: #DE330D;
}

.InputText_iconError__2J3KQ {
  color: #DE330D;
  margin-right: 16px;
}

.InputText_inputDisabled__1vBMz {
  background: #F1F1F4;
  border: 1px solid #C4C4C4;
  cursor: not-allowed;
  pointer-events: none;
}

.InputText_inputDisabled__1vBMz input {
  cursor: not-allowed;
}

.InputText_inputDisabled__1vBMz:hover {
  border-color: #C4C4C4;
}

.InputText_inputDisabled__1vBMz:focus-within {
  box-shadow: none;
}

.InputText_iconContainer__2KaTa {
  width: 38px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  border-radius: 0 5px 5px 0px;
}

.InputText_container__3-OtW {
  width: 100%;
}

.Security_error__2BJRt {
    background-color: #F8D7DA;
    color: #DE330D;
    padding: 4px 16px;
    border-radius: 5px;
    width: -webkit-min-content;
    width: min-content;
    max-width: 400px;
    width: -webkit-max-content;
    width: max-content;
}

.Security_success__3wZGt {
    background-color: #D1ECF1;
    color: #155724;
    padding: 4px 16px;
    border-radius: 5px;
    max-width: 400px;
    width: -webkit-max-content;
    width: max-content;
}

.Security_passwordForm__2UGQC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.Security_passwordForm__2UGQC button {
    margin-left: 16px;
}

.Security_passwordForm__2UGQC > div {
    max-width: 320px;
}

.Security_passwordInput__24bXi > div {
    height: 48px;
}

.Security_passwordInput__24bXi > p {
    margin-top: 0;
}
.SecurityPassword_label__eIr0K {
    margin-bottom: 4px;
}

.CopyPopUp_background__3sQRf {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    background-color: rgba(255, 255, 255, 0.5);
}

.CopyPopUp_modal__1F_gh {
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

    width: 90%;
    max-width: 320px;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
}

.CopyPopUp_modal__1F_gh > div {
    max-height: 200px;
}

.CopyPopUp_title__1dL84 {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
}

.CopyPopUp_button__1HCEz {
    width: 100%;
}

.CopyPopUp_description__m1mxZ {
    color: #72757E;
    text-align: center;
}

.CopyPopUp_custom__18i0w {
    color: #72757E;
    width: unset;
    height: unset;
    min-width: unset;
    padding: 0;
    transition: .3s;
    font-size: 14px;
    margin: 24px 0 8px auto;
}

.CopyPopUp_icon__3n2pl {
    font-size: 14px;
}

.CopyPopUp_custom__18i0w:hover {
    color: #4F67F3;
}

.CopyPopUp_token__JHoV6 {
    background-color: #EFEFEF;
    border-radius: 5px;
    padding: 8px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
    margin-top: 0;
    margin-bottom: 24px;
    text-align: center;
}

.CopyPopUp_token__JHoV6::-webkit-scrollbar {
    height: 4px;
}

.CopyPopUp_token__JHoV6::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.CopyPopUp_error__1QDeU {
    background-color: #F8D7DA;
    color: #DE330D;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;

    display: -webkit-flex;

    display: flex;
}

.CopyPopUp_error__1QDeU .CopyPopUp_icon__3n2pl {
    margin-right: 8px;
    font-size: 18px;
}

.CopyPopUp_lineHeight__2dews {
    line-height: 200%;
}

.ErrorAnimation_icon__2dnCb {
  padding: 26px;
  background-color: #fff;
}

.ErrorAnimation_iconError__2Z2_6 {
  border-radius: 50%;
  border: 4px solid #F27474;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  margin: auto;
  -webkit-animation: ErrorAnimation_animateErrorIcon__3GkCw .5s;
          animation: ErrorAnimation_animateErrorIcon__3GkCw .5s;
}

.ErrorAnimation_iconError__2Z2_6::after, .ErrorAnimation_iconError__2Z2_6::before {
  background: #fff;
  content: '';
  height: 120px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 60px;
}

.ErrorAnimation_iconError__2Z2_6::before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  -webkit-transform-origin: 60px 60px;
          transform-origin: 60px 60px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ErrorAnimation_iconError__2Z2_6::after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  -webkit-transform-origin: 0 60px;
          transform-origin: 0 60px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: ErrorAnimation_rotatePlaceholder__3MXo- 4.25s ease-in;
          animation: ErrorAnimation_rotatePlaceholder__3MXo- 4.25s ease-in;
}


.ErrorAnimation_iconErrorX__fj4A2 {
  display: block;
  position: relative;
  z-index: 2;
}

.ErrorAnimation_iconErrorPlaceholder__2hRaM {
  border-radius: 50%;
  border: 4px solid rgba(200, 0, 0, .2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}

.ErrorAnimation_iconErrorFix__1bACk {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}

.ErrorAnimation_iconErrorLeft__26UnE, .ErrorAnimation_iconErrorRight__1nOHg {
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
  background-color: #F27474;
  top: 37px;
  width: 47px;
}

.ErrorAnimation_iconErrorLeft__26UnE {
  left: 17px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: ErrorAnimation_animateXLeft__11-jP .75s;
          animation: ErrorAnimation_animateXLeft__11-jP .75s;
}

.ErrorAnimation_iconErrorRight__1nOHg {
  right: 16px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: ErrorAnimation_animateXRight__1v5rp .75s;
          animation: ErrorAnimation_animateXRight__1v5rp .75s;
}

@-webkit-keyframes ErrorAnimation_rotatePlaceholder__3MXo- {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100%,12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}

@keyframes ErrorAnimation_rotatePlaceholder__3MXo- {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100%,12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}
@-webkit-keyframes ErrorAnimation_animateErrorIcon__3GkCw {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes ErrorAnimation_animateErrorIcon__3GkCw {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes ErrorAnimation_animateXLeft__11-jP {
  0%, 65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes ErrorAnimation_animateXLeft__11-jP {
  0%, 65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@-webkit-keyframes ErrorAnimation_animateXRight__1v5rp {
  0%, 65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}
@keyframes ErrorAnimation_animateXRight__1v5rp {
  0%, 65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

.SecurityAccount_error__1ST0V {
    background-color: #F8D7DA;
    color: #DE330D;
}

.SecurityAccount_success__379ck {
    background-color: #D1ECF1;
    color: #155724;
}

.SecurityAccount_status__3XupC {
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.SecurityAccount_status__3XupC .SecurityAccount_title__2vwEG {
    margin: 0;
    font-weight: 500;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.SecurityAccount_status__3XupC .SecurityAccount_icon__3FLYP {
    margin-right: 8px;
}

.SecurityAccount_status__3XupC .SecurityAccount_description__1Ag2- {
    margin: 0;
}

.SecurityAccount_label__27RgZ {
    margin-bottom: 4px;
}

.SecurityAccount_background___PTue {
    position: fixed;
    top: 0;
    right: 0;

    width: 100%;
    height: 100vh;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background-color: rgba(255, 255, 255, 0.75);
}

.SecurityAccount_modal__3qL1S {
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

    padding: 16px;
    border-radius: 5px;
}

.SecurityAccount_buttonGroup__3ZT4f {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.SecurityAccount_buttonGroup__3ZT4f button {
    width: 49%;
}
.SecurityAccount_custom__3wwvu {
    background-color: #EFEFEF;
    color: #72757E;
    transition: .3s;
}

.SecurityAccount_custom__3wwvu:hover {
    background-color: #CCCCCC;
}

.SecurityAccount_title__2vwEG {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.SecurityAccount_description__1Ag2- {
    margin-top: 4px;
}

.Personal_error__2oQOr {
    background-color: #F8D7DA;
    color: #DE330D;
    padding: 4px 16px;
    border-radius: 5px;
    width: -webkit-min-content;
    width: min-content;
    max-width: 400px;
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
}

.Personal_success__3R6Sa {
    background-color: #D1ECF1;
    color: #155724;
    padding: 4px 16px;
    border-radius: 5px;
    max-width: 400px;
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
}
.iti__selected-dial-code {
  background: none;
  color: black;
}

.iti__flag-container:focus-within {
  outline: none;
  border: none;
}

.InputPhone_phoneInput__2cJOd {
  width: 98%;
}

.InputPhone_inputPhoneContainer__x-kXe {
  padding-left: 0;
}

.InputPhone_inputPhoneContainer__x-kXe.InputPhone_error__3og3O {
  border-color: #DE330D;
}

.InputPhone_inputPhoneContainer__x-kXe > div {
  width: 100%;
}

.InputPhone_error__3og3O {
  color: #DE330D;
  margin-bottom: 0;
}

.InputPhone_iconError__IZ2BO {
  margin-right: 4px;
}

.Roles_section__2vghQ {
    height: 88%;
}
.Roles_section__2vghQ header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    height: 10%;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
}

.Roles_section__2vghQ main {
    height: 80%;
    overflow-y: scroll;
}

.Roles_section__2vghQ header h2 {
    font-size: 24px;
}

.Roles_buttonGroup__3Ojh1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Roles_buttonGroup__3Ojh1 button + button {
    margin-left: 16px;
}

.Roles_cancel__u3Pow {
    color: #72757E;
    border: 1px solid #CCCCCC;
    transition: .3s;
}

.Roles_cancel__u3Pow:hover {
    background-color: #EFEFEF;
}

.Roles_search__1v1Yg {
    height: 10%;
    min-height: 70px;
    padding: 0 24px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #E5E5E5;
}

.Roles_icon__2NmO1 {
    margin-right: 8px;
    color: #72757E;
}

.Roles_search__1v1Yg input {
    height: 100%;
    width: 100%;

    border: none;
    background: none;
    outline: none;

    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
}
.ExpandablesRoles_button__RtNS5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    background: #F5F7FF;
    padding: 0 16px;

    cursor: pointer;
}

.ExpandablesRoles_row__ofeNX, .ExpandablesRoles_role__28xyx {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.ExpandablesRoles_role__28xyx {
    padding: 0 16px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.ExpandablesRoles_list__3eH5w {
    margin: 0;
}

.ExpandablesRoles_icon__2PRdv {
    margin-right: 8px;
}

.ExpandablesRoles_title__3sdaH {
    font-weight: 500;
}

.ExpandablesRoles_selected__1S5Oe {
    color: #4F67F3;
    margin-left: 8px;
}
.Checkbox_checkbox__2D1SQ {
    height: 32px;
    width: 32px;

    background-color: white;
    border: 1px solid #EFEFEF;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    transition: .3s;
    cursor: pointer;
}

.Checkbox_checkbox__2D1SQ.Checkbox_checked__1kqL0, .Checkbox_checkbox__2D1SQ:hover {
    color: #4F67F3;
    border-color: #4F67F3;
}
.RolesList_sidebar__3O0Tk {
    grid-area: sidebar;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 65px);
    overflow: hidden;
}

.RolesList_sidebar__3O0Tk header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    height: 75px;
    padding: 0 24px;
    border-bottom: 1px solid #E5E5E5;
}

.RolesList_list__2-5JR {
    height: calc(100% - 75px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.RolesList_sidebar__3O0Tk header button {
    margin-left: 8px;
    width: 64px;
    min-width: unset;
    padding: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.RolesList_sidebar__3O0Tk header button span {
    margin: 0;
}

.RolesList_searchbar__1u_O_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    color: #72757E;
}

.RolesList_input__8ydGJ {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto;
    color: #72757E;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}
.ExpandablesRoles_selected__3sCpl > .ExpandablesRoles_button__VV9K4 {
    color: white;
    background-color: #4F67F3;
}

.ExpandablesRoles_button__VV9K4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    padding: 0 16px;

    cursor: pointer;
    background-color: #F5F7FF;
}

.ExpandablesRoles_row__BJ2lS, .ExpandablesRoles_role__ab8_H {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.ExpandablesRoles_role__ab8_H {
    padding: 0 16px;
    border-bottom: 1px solid #E5E5E5;
}

.ExpandablesRoles_list__2TayT {
    margin: 0;
    padding: 0;
}

.ExpandablesRoles_icon__2rhee {
    margin-right: 8px;
    font-size: 18px !important;
}

.ExpandablesRoles_title__2ObI5 {
    font-weight: 500;
}

.ExpandablesRoles_selected__3sCpl {
    color: #4F67F3;
}

.ExpandablesRoles_child___jznH {
    padding-left: 8px;
}
.RolesDescription_main__2MIl7 {
    grid-area: main;
    padding: 24px;
    box-sizing: border-box;
    background-color: #F4F4F4;
    height: calc(100vh - 65px);
}

.RolesDescription_main__2MIl7 header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 10%;
}

.RolesDescription_main__2MIl7 main {
    height: 85%;
}

.RolesDescription_title__3wHFe {
    margin: 0;
    margin-bottom: 8px;
    font-size: 24px;
}

.RolesDescription_subtitle__HIfy0 {
    margin: 0;
    font-size: 24px;
}

.RolesDescription_role__5G_Ux {
    background-color: white;
    margin-top: 16px;
}


.RolesDescription_row__1zvuM {
    height: 10%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;
}

.RolesDescription_buttonGroup__3ClmF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.RolesDescription_buttonGroup__3ClmF button + button {
    margin-left: 8px;
}

.RolesDescription_default__37Lk4 {
    margin: 0;
    color: #72757E;
}

.RolesDescription_switchWrapper__1ea3G {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.RolesDescription_subroles__rfl16 {
    height: 88%;
    overflow-y: scroll;
}

.RolesDescription_list__1M5X1 {
    padding: 0;
    margin: 0;
    list-style: none;
}

.RolesDescription_list__1M5X1 li {
    padding: 8px 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
}

.RolesDescription_button__1kCoQ {
    padding: 0;
    min-width: unset;
}

.RolesDescription_empty__3FWOw {
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.RolesDescription_empty__3FWOw p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: #72757E;
}
/* The switch - the box around the slider */
.RolesDescription_switch__3oMq7 {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;
    margin-right: 8px;
}

/* Hide default HTML checkbox */
.RolesDescription_switch__3oMq7 input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.RolesDescription_slider__1nwY9 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.RolesDescription_slider__1nwY9:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .RolesDescription_slider__1nwY9 {
    background-color: #2196F3;
}

input:focus + .RolesDescription_slider__1nwY9 {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .RolesDescription_slider__1nwY9:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.RolesDescription_slider__1nwY9.RolesDescription_round__3gf0l {
    border-radius: 34px;
}

.RolesDescription_slider__1nwY9.RolesDescription_round__3gf0l:before {
    border-radius: 50%;
}
.RoleForm_overlay__1Z8GC {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background-color: rgba(0,0,0,0.75);
}

.RoleForm_list__2tqHn {
    max-height: 40vh;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}

.RoleForm_error__2XrXG {
    background-color: undefined;
    color: #DE330D;
}

.RoleForm_list__2tqHn li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-right: 8px;
}

.RoleForm_popup__ZCOsQ {
    background-color: white;
    width: 50%;
    min-width: 320px;
    max-height: 90vh;
    padding: 16px;
    border-radius: 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.RoleForm_footer__Hjq1l {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;

    margin-top: 16px;
}

.RoleForm_footer__Hjq1l button + button {
    margin-left: 8px;
}

.RoleForm_header__2bcXf h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.RoleForm_searchbar__27dQ1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    color: #72757E;
    margin-bottom: 16px;
}

.RoleForm_input__ZvhG_ {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto;
    color: #72757E;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}

.RoleForm_selected__3omCM {
    background-color: #EBEEFF;
    color: #4F67F3;
}

.RoleForm_create__xnCJh {
    margin-top: 8px;
    margin-bottom: 0;
}

.RoleForm_create__xnCJh span {
    color: #4F67F3;
    font-weight: 500;
}

.RoleForm_switch__3jvmd {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.RoleForm_switch__3jvmd input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.RoleForm_slider__Qbp14 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.RoleForm_slider__Qbp14:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .RoleForm_slider__Qbp14 {
    background-color: #2196F3;
}

input:focus + .RoleForm_slider__Qbp14 {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .RoleForm_slider__Qbp14:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.RoleForm_slider__Qbp14.RoleForm_round__3c9Er {
    border-radius: 34px;
}

.RoleForm_slider__Qbp14.RoleForm_round__3c9Er:before {
    border-radius: 50%;
}

.RoleForm_default__3gDJH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 8px;
}

.RoleForm_default__3gDJH label {
    margin-right: 8px;
}

.RoleForm_default__3gDJH p {
    color: #72757E;
    margin: 0;
}

.RoleForm_bold__3HzlO {
    font-weight: 500;
}

.RoleForm_cancel__3nWX7 {
    color: #72757E;
    border: 1px solid #CCCCCC;
    background: none;
}

.RoleForm_cancel__3nWX7:hover {
    background-color: #EFEFEF;
}

.RoleForm_success__2OFAO {
    background-color: #D1ECF1;
    color: #155724;
    padding: 4px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.RoleForm_error__2XrXG {
    background-color: #F8D7DA;
    color: #DE330D;
    padding: 4px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.RoleForm_icon__32J9u {
    margin-right: 4px;
}
.Admin_admin__1_3Iy {
    width: 100%;

    display: grid;
    grid-template:
        'header header header' 65px
        'sidebar main main' auto
        'sidebar main main' auto / minmax(auto, 430px) 1fr 1fr;

}
.Debug_section__2pLdm {
    max-width: 1440px;
    margin: auto;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
}

.Debug_section__2pLdm p {
    overflow-wrap: anywhere;
}

.Debug_title__3AOt4 {
    font-weight: 500;
}

.Debug_container__1G7Dc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Debug_tokenWrapper__236Sh, .Debug_decodedTokenWrapper__6NXsi {
    width: 49%;
}

.Debug_token__2x-yi {
    background-color: #EFEFEF;
    padding: 24px;
    border-radius: 5px;
}

.Debug_row__1VlmU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Debug_copy__2LLdP {
    color: #72757E;
    padding: 0;
    min-width: unset;
}

.Debug_copy__2LLdP:hover {
    color: #4F67F3;
}

.Debug_container__1G7Dc {
    margin-top: 24px;
}

.Debug_error__1MS96 {
    background-color: #F8D7DA;
    color: #DE330D;
    padding: 4px;
    border-radius: 5px;
    margin: 0 0 8px 0;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Debug_icon__2C4Vd {
    margin-right: 4px;
}

.Debug_list__1qDAl {
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Debug_item__1wXbo {
    min-width: 150px;
    padding: 4px;
    box-sizing: border-box;
}

.Debug_item__1wXbo.Debug_title__3AOt4 {
    font-weight: 500;
    margin-bottom: 0;
}

.Debug_item__1wXbo.Debug_value__1RBve {
    margin-top: 4px;
    color: #72757E;
}

.Debug_table__eR9-o {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 700px;
    overflow-y: scroll;
}
.App_main__174yG {
    width: 100%;
    /* min-height: calc(100vh - 75px); */
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #EBEEFF;
    overflow: hidden;
    position: relative;
}

.App_background__2yJOO {
    position: absolute;
    z-index: 0;
    width: 100%;
    bottom: 0;
}

.App_content__1O9U6 {
    z-index: 10;
}

.App_footer__2g852 {
    height: 75px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;

    display: none;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.App_nav__1KvSs a {
    text-decoration: none;
    color: black;
    transition: .3s;
    font-size: 16px;
}

.App_nav__1KvSs a:hover {
    color: #4F67F3;
}

.App_nav__1KvSs a + a {
    margin-left: 16px;
}

.App_logo__7IXQI {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.App_selection__2DMAD {
    border: none;
    background: none;
    font-family: 'Roboto';
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

@media screen and (max-width: 500px) {
    .App_footer__2g852 {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        height: auto;
    }

    .App_nav__1KvSs {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .App_nav__1KvSs a + a {
        margin-left: 0;
    }

    .App_nav__1KvSs a {
        margin-bottom: 8px;
    }

    .App_content__1O9U6 {
        width: 100%;
    }
}

.Applications_applications__1hlLj {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.Applications_list__3Sbiz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 0;
}

.Applications_list__3Sbiz button {
    width: 100%;
    height: 86px;
    background-color: #EBEEFF;
    border-radius: 5px;
    transition: .3s;
}

.Applications_list__3Sbiz button:hover {
    background-color: #7D8FF5;
    color: white;
}

.Applications_list__3Sbiz button + button {
    margin-top: 16px;
}
.Checkbox_container__3Gq5a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Checkbox_container__3Gq5a button {
  outline: none;
  margin-right: 8px;
}

.Checkbox_checkbox__A73du {
  width: 25px;
  height: 25px;
  background: none;
}

.Checkbox_disabled__3CX-2 {
  background-color: #EFEFEF;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #CCCCCC;
  outline: none;
  cursor: not-allowed;
  pointer-events: none;
}

.Checkbox_unchecked__1u6eT {
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  margin-right: 8px;
  background-color: none;

  transition: background 0.3s;
}

.Checkbox_unchecked__1u6eT:hover {
  cursor: pointer;
}

.Checkbox_label__YrTcc {
  margin-bottom: 0;
  margin-top: 0;
}

.Checkbox_labelDisabled__3ReJp {
  color: #CCCCCC;
}

.Checkbox_checked__NcFMR {
  border: 1px solid #4F67F3;
  background-color: #4F67F3;
  border-radius: 2px;

  color: white;
  border: none;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  transition: background 0.3s;
}

.Checkbox_checked__NcFMR:hover {
  cursor: pointer;
}

.Checkbox_icon__IYVpj {
  font-size: 14px;
}

.PasswordInput_row__HmKtX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.PasswordInput_showPassword__9nvxb {
  border: none;
  color: #4F67F3;
  width: 100px;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.PasswordInput_showPassword__9nvxb:hover {
  color: #404FA1;
}

.PasswordInput_showPassword__9nvxb:focus {
  color: color-purple-light;
}

.PasswordInput_icon__3uAe6 {
  margin-right: 8px;
}

.PasswordInput_required__AYvPs {
  color: #4F67F3;
}

.PasswordInput_input__1OfHo {
  width: 100%;
}

.PasswordInput_valid__1goxp {
  color: #0EDC97;
}

.PasswordInput_invalid__1rjp- {
  color: #DE330D;
}

.PasswordInput_hintIcon__I4gUn {
  margin-right: 8px;
}

.PasswordInput_passwordHint__1h43s p {
  font-size: 14px;
  margin: 8px 0;
}

.PasswordInput_errorInput__Zv4Dv {
  border-color: #DE330D;
}

.PasswordInput_errorHint__6_QrX {
  margin-top: 0;
  color: #DE330D;
  font-size: 12px;
}
.AccountForm_buttonGroup___Coy8 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 24px;
}

.AccountForm_buttonGroup___Coy8 > button {
    width: 100%;
}

.AccountForm_logIn__3OoPs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.AccountForm_link__2KFLV {
    font-weight: 500;
    text-decoration: none;
    margin-left: 8px;
}

.AccountForm_checkbox__37S1y a {
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
}

.AccountForm_checkboxContainer__1EWeZ {
    margin-top: 16px;
}

.AccountForm_checkboxContainer__1EWeZ button {
    border-color: #CCCCCC;
}

.AccountForm_hintError__279_- {
    color: #DE330D;
    font-size: 12px;
}

.AccountForm_errorServer__3ARIF {
    color: #DE330D;
    background-color: #F8D7DA;
    padding: 8px;
    border-radius: 5px;
}

.AccountForm_icon__1YuUj {
    margin-right: 8px;
}

.AccountForm_linkSales__3JRlg {
    margin-left: 0;
}

.AccountForm_link__2KFLV {
    margin-left: 8px;
}
.Account_account__2OCPo {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
    margin: 48px 0;
}

.Account_checkIcon__2b88C {
    color: #0EDC97;
    font-size: 75px;
    margin: auto;
    margin-top: 48px;
}

.Account_description__1IXye {
    color: #72757E;
}

.Account_success__ZnqsU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.Account_title__3IKme {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 24px;
}

.Account_button__Okhs7 {
    margin-bottom: 48px;
}

@media screen and (max-width: 500px) {
    .Account_account__2OCPo {
        background: none;
        margin: auto;
    }

    .Account_checkIcon__2b88C {
        font-size: 72px;
    }
}
.Password_password__19q36 {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.Password_title__9P6zF {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.Password_description__3efYM {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #72757E;
    text-align: center;
    width: 90%;
    margin:auto;
}

.Password_buttonGroup__2l1eu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 24px;
}

.Password_buttonGroup__2l1eu button {
    width: 100%;
}

.Password_buttonGroup__2l1eu button + button {
    margin-top: 8px;
}

.Password_error__1L1-U {
    color: #DE330D;
}

.Password_checkboxContainer__2M2v3 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.Password_checkboxContainer__2M2v3 > div + div {
    margin-top: 16px;
}

@media screen and (max-width: 500px) {
    .Password_password__19q36 {
        width: 80%;
        margin: auto;
    }
}
.Confirm_modal__1nwt5 {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 650px;
}

.Confirm_button__Rv2Ed {
    background-color: #4F67F3;
    color: white;
    text-decoration: none;
    font-weight: 500;
    height: 48px;
    width: 100%;
    margin-top: 24px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: .3s;
}

.Confirm_button__Rv2Ed:hover {
    color: white;
    background-color: #404FA1;
}

.Confirm_modal__1nwt5 h1 {
    text-align: center;
    font-size: 32px;
    margin: 0;
}

.Confirm_modal__1nwt5 .Confirm_description__2nI9p {
    text-align: center;
    font-size: 18px;
    margin-top: 8px;
    color: #72757E;
}

.Confirm_success__HvrKn {
    margin-top: 32px;
}
/**
* Extracted from: SweetAlert
* Modified by: Istiak Tridip
*/
.SuccessAnimation_successCheckmark__3DHy8 {
    width: 80px;
    height: 115px;
    margin: 0 auto;
}

.SuccessAnimation_checkIcon__2JJfn {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
}

.SuccessAnimation_checkIcon__2JJfn::before {
    top: 3px;
    left: -2px;
    width: 30px;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.SuccessAnimation_checkIcon__2JJfn::after {
    top: 0;
    left: 30px;
    width: 60px;
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    -webkit-animation: SuccessAnimation_rotate-circle__ZCXti 4.25s ease-in;
            animation: SuccessAnimation_rotate-circle__ZCXti 4.25s ease-in;
}

.SuccessAnimation_checkIcon__2JJfn::before, .SuccessAnimation_checkIcon__2JJfn::after {
    content: '';
    height: 100px;
    position: absolute;
    background: transparent;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.SuccessAnimation_iconLine__3e4i_ {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.SuccessAnimation_iconLine__3e4i_.SuccessAnimation_lineTip__3ZZM3 {
    top: 46px;
    left: 14px;
    width: 25px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-animation: SuccessAnimation_icon-line-tip__3q1Lh 0.75s;
            animation: SuccessAnimation_icon-line-tip__3q1Lh 0.75s;
}

.SuccessAnimation_iconLine__3e4i_.SuccessAnimation_lineLong__2_ytS {
    top: 38px;
    right: 8px;
    width: 47px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-animation: SuccessAnimation_icon-line-long__1dGfm 0.75s;
            animation: SuccessAnimation_icon-line-long__1dGfm 0.75s;
}

.SuccessAnimation_iconCircle__27wmQ {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, .5);
}

.SuccessAnimation_iconFix__3GnV9 {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    background-color: transparent;
}

@-webkit-keyframes SuccessAnimation_rotate-circle__ZCXti {
    0% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
    }
    100% {
        -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
    }
}

@keyframes SuccessAnimation_rotate-circle__ZCXti {
    0% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
    }
    100% {
        -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
    }
}

@-webkit-keyframes SuccessAnimation_icon-line-tip__3q1Lh {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes SuccessAnimation_icon-line-tip__3q1Lh {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@-webkit-keyframes SuccessAnimation_icon-line-long__1dGfm {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes SuccessAnimation_icon-line-long__1dGfm {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.LogIn_logIn__1NLiA {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.LogIn_logo__J2_dW {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

.LogIn_buttonGroup__j8CVf {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 24px;
}

.LogIn_row__Y-yYP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
 }

.LogIn_row__Y-yYP p {
    margin: 0;
}

.LogIn_label__3f5wM {
    font-weight: 500;
}

.LogIn_inputWrapper__N6pcC input {
    all: unset;
    width: 90%;
    padding-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.LogIn_inputWrapper__N6pcC button {
    width: 10%;
}

.LogIn_inputWrapper__N6pcC {
    border: 1px solid #EFEFEF;
    height: 42px;
    border-radius: 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    background-color: white;
}

.LogIn_inputWrapper__N6pcC:hover {
    cursor: pointer;
    border-color: #4F67F3;
}

.LogIn_inputWrapper__N6pcC:focus-within {
    border: 1px solid #4F67F3;
    box-shadow: 0px 0px 6px #b1bdff;
}

.LogIn_separator__GHhzB {
    color: #CCCCCC;
}

.LogIn_separator__GHhzB hr {
    width: 40%;
    height: 0;
    border: none;
    border-bottom: 1px solid #EFEFEF;
}

.LogIn_separator__GHhzB p {
    width: 20%;
    text-align: center;
    color: #CCCCCC;
    margin: 0;
}

.LogIn_buttonGroup__j8CVf button + button {
    margin-top: 8px;
}

.LogIn_custom__3DQrw {
    font-weight: 400;
    font-size: 14px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0;
    min-width: unset !important;
}

.LogIn_visibility__11LRC {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.LogIn_visibility__11LRC .LogIn_icon__2zja0 {
    font-size: 16px;
}

.LogIn_hint__4bCEr {
    margin-top: 4px;
    font-size: 12px;
    color: #DE330D;
}

.LogIn_errorInput__3zz0u {
    border-color: #DE330D;
}

.LogIn_errorMessage__20vj2 {
    color: #DE330D;
}

.LogIn_error__3HeFN {
    color: #DE330D;
    background-color: #fff1f3;
    padding: 4px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.LogIn_error__3HeFN .LogIn_icon__2zja0 {
    margin-right: 4px;
}

@media screen and (max-width: 500px) {
    .LogIn_logIn__1NLiA {
        background: none;
        width: 90%;
        margin: auto;
    }
}
.UnlockAnimation_container__1O55G {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 150px;
    position: absolute;
    top: -35px;
    left: 22.5px;
}

/* Locked */
.UnlockAnimation_lock__2ttHX {
    width: 24px;
    height: 21px;
    border: 3px solid #DE330D;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}
.UnlockAnimation_lock__2ttHX:after {
    content: "";
    display: block;
    background: #DE330D;
    width: 3px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5px 0 0 -2px;
    transition: all 0.1s ease-in-out;
}
.UnlockAnimation_lock__2ttHX:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    bottom: 100%;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border: 3px solid #DE330D;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom: 0;
    transition: all 0.1s ease-in-out;
}
/* Locked Hover */
.UnlockAnimation_lock__2ttHX:hover:before {
    height: 12px;
}
/* Unlocked */
.UnlockAnimation_unlocked__10LjY {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
}
.UnlockAnimation_unlocked__10LjY:before {
    bottom: 130%;
    left: 31%;
    margin-left: -11.5px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.UnlockAnimation_unlocked__10LjY,
.UnlockAnimation_unlocked__10LjY:before {
    border-color: #155724;
}
.UnlockAnimation_unlocked__10LjY:after {
    background: #155724;
}
/* Unlocked Hover */
.UnlockAnimation_unlocked__10LjY:hover {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
}
.UnlockAnimation_unlocked__10LjY:hover:before {
    height: 10px;
    left: 40%;
    bottom: 124%;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
}
.bounceIn {
    opacity: 0;
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn;
    -webkit-animation-duration: 450ms;
            animation-duration: 450ms;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes bounceIn{
    0%{
        opacity: 0;
        -webkit-transform: scale(0.3) translate3d(0,0,0);
                transform: scale(0.3) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    80%{
        opacity: 1;
        -webkit-transform: scale(0.89);
                transform: scale(0.89);
    }
    100%{
        opacity: 1;
        -webkit-transform: scale(1) translate3d(0,0,0);
                transform: scale(1) translate3d(0,0,0);
    }
}

@keyframes bounceIn{
    0%{
        opacity: 0;
        -webkit-transform: scale(0.3) translate3d(0,0,0);
                transform: scale(0.3) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    80%{
        opacity: 1;
        -webkit-transform: scale(0.89);
                transform: scale(0.89);
    }
    100%{
        opacity: 1;
        -webkit-transform: scale(1) translate3d(0,0,0);
                transform: scale(1) translate3d(0,0,0);
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 450ms;
            animation-duration: 450ms;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.fadeOut {
    opacity: 1;
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: 450ms;
            animation-duration: 450ms;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

.slideInTop {
  opacity: 0;
  -webkit-animation-name: slideInTop;
          animation-name: slideInTop;
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes slideInTop {
  0%{
      opacity: 0;
      -webkit-transform: translateY(-600px);
              transform: translateY(-600px);
  }
  50%{
      opacity: 0.5;
      -webkit-transform: translateY(-300px);
              transform: translateY(-300px);
  }
  100%{
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes slideInTop {
  0%{
      opacity: 0;
      -webkit-transform: translateY(-600px);
              transform: translateY(-600px);
  }
  50%{
      opacity: 0.5;
      -webkit-transform: translateY(-300px);
              transform: translateY(-300px);
  }
  100%{
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

.slideInRight {
  opacity: 0;
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight;
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes slideInRight {
  0%{
      opacity: 0;
      -webkit-transform: translateX(150%);
              transform: translateX(150%);
  }
  50%{
      opacity: 0.5;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
  }
  100%{
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}

@keyframes slideInRight {
  0%{
      opacity: 0;
      -webkit-transform: translateX(150%);
              transform: translateX(150%);
  }
  50%{
      opacity: 0.5;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
  }
  100%{
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}

.DoubleAuth_lock__2HFSe {
    height: 75px;
    width: 75px;
    margin: 16px auto;
    border-radius: 75px;
    background-color: #f9e2e2;
    position: relative;
}

.DoubleAuth_unlock__2twZ_ {
    height: 75px;
    width: 75px;
    margin: 16px auto;
    border-radius: 75px;
    background-color: #D1ECF1;
    position: relative;
}

.DoubleAuth_loading__Fia74 {
    height: 75px;
    width: 75px;
    border-radius: 75px;

    margin: 16px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background-color: #EBEEFF;
}

.DoubleAuth_loading__Fia74 .DoubleAuth_icon__ud7qR {
    font-size: 24px;
    color: #4F67F3;
}

.DoubleAuth_button__1sQRl {
    width: 100%;
    margin-top: 16px;
}

.DoubleAuth_title__9j31s {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: auto;
}

.DoubleAuth_description__3Hxck {
    color: #72757E;
    text-align: center;
    margin: 8px;
}
.ForceReset_section__3mVTm {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ForceReset_illustration__2gUqh {
    margin-top: 24px;

    width: 100px;
    height: 100px;
    border-radius: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background-color: #FFF3CD;
    color: #FBC506;
}

.ForceReset_icon__x_6zZ {
    font-size: 48px !important;
}

.ForceReset_section__3mVTm h1 {
    font-size: 24px;
    margin-bottom: 0;
}

.ForceReset_description__1Dvp7 {
    color: #72757E;
    text-align: center;
}

.ForceReset_section__3mVTm button {
    width: 100%;
}
body {
  margin: 0;
}

button {
  background: none;
  outline: none;
  margin: 0;
  border: none;
  cursor: pointer;
  color: inherit;
}

a {
  color: black;
  text-decoration: underline;
}

button, .button {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size:18px;
}

h1, .h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:48px;
}

h2, .h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:36px;
}

h3, .h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:30px;
}

h4, .h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:24px;
}

h5, .h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:20px;
}

h6, .h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size:18px;
}

a, p, span, label, .body-1, .p {
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  line-height: 150%;
}

b, .bold {
  font-family: 'Roboto', sans-serif;
  font-size:18px;
  font-weight: 700;
}

a, .link {
  background: none;
  border: none;
  color: #526AF2;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
}

a:hover, .link:hover {
  color: #404FA1;
}

.body-2 {
  font-family: 'Roboto', sans-serif;
  font-size:16px;
}

.caption {
  font-family: 'Roboto', sans-serif;
  font-size:14px;
}

.subtitle-1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-transform: uppercase;
}

.subtitle-2 {
  font-family: 'Raleway', sans-serif;
  font-size:16px;
}

@media screen and (max-width: 480px) {
  button, .button {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size:18px;
  }

  h1, .h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:40px;
  }

  h2, .h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:36px;
  }

  h3, .h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:30px;
  }

  h4, .h4 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:20px;
  }

  h5, .h5 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:18px;
  }

  h6, .h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:16px;
  }

  a, p, span, label, .body-1, .p {
    font-family: 'Roboto', sans-serif;
    font-size:14px;
    line-height: 150%;
  }

  b, .bold {
    font-family: 'Roboto', sans-serif;
    font-size:16px;
    font-weight: 700;
  }

  a, .link {
    background: none;
    border: none;
    color: #526AF2;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
  }

  a:hover, .link:hover {
    color: #404FA1;
  }

  .body-2 {
    font-family: 'Roboto', sans-serif;
    font-size:14px;
  }

  .caption {
    font-family: 'Roboto', sans-serif;
    font-size:13px;
  }

  .subtitle-2 {
    font-family: 'Raleway', sans-serif;
    font-size:16px;
  }
}

