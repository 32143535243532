@value colors: "../../../components/library/style/colors.css";
@value color-text-subtle, color-purple-bg, color-purple, color-success-light from colors;

.lock {
    height: 75px;
    width: 75px;
    margin: 16px auto;
    border-radius: 75px;
    background-color: #f9e2e2;
    position: relative;
}

.unlock {
    height: 75px;
    width: 75px;
    margin: 16px auto;
    border-radius: 75px;
    background-color: color-success-light;
    position: relative;
}

.loading {
    height: 75px;
    width: 75px;
    border-radius: 75px;

    margin: 16px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: color-purple-bg;
}

.loading .icon {
    font-size: 24px;
    color: color-purple;
}

.button {
    width: 100%;
    margin-top: 16px;
}

.title {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: auto;
}

.description {
    color: color-text-subtle;
    text-align: center;
    margin: 8px;
}