@value colors: "../../components/library/style/colors.css";
@value color-success, color-text-subtle from colors;

.account {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
    margin: 48px 0;
}

.checkIcon {
    color: color-success;
    font-size: 75px;
    margin: auto;
    margin-top: 48px;
}

.description {
    color: color-text-subtle;
}

.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 24px;
}

.button {
    margin-bottom: 48px;
}

@media screen and (max-width: 500px) {
    .account {
        background: none;
        margin: auto;
    }

    .checkIcon {
        font-size: 72px;
    }
}