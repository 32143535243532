@value colors: "../../components/library/style/colors.css";
@value color-text-subtle, color-error from colors;

.password {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: color-text-subtle;
    text-align: center;
    width: 90%;
    margin:auto;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.buttonGroup button {
    width: 100%;
}

.buttonGroup button + button {
    margin-top: 8px;
}

.error {
    color: color-error;
}

.checkboxContainer {
    margin-top: 16px;
    margin-bottom: 16px;
}

.checkboxContainer > div + div {
    margin-top: 16px;
}

@media screen and (max-width: 500px) {
    .password {
        width: 80%;
        margin: auto;
    }
}