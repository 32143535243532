@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray-light from colors;

.main {
    grid-area: main;
    padding: 24px;
    box-sizing: border-box;
    background-color: #F4F4F4;
    height: calc(100vh - 65px);
}

.main header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.main main {
    height: 85%;
}

.title {
    margin: 0;
    margin-bottom: 8px;
    font-size: 24px;
}

.subtitle {
    margin: 0;
    font-size: 24px;
}

.role {
    background-color: white;
    margin-top: 16px;
}


.row {
    height: 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
}

.buttonGroup button + button {
    margin-left: 8px;
}

.default {
    margin: 0;
    color: color-text-subtle;
}

.switchWrapper {
    display: flex;
    align-items: center;
}

.subroles {
    height: 88%;
    overflow-y: scroll;
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.list li {
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
}

.button {
    padding: 0;
    min-width: unset;
}

.empty {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.empty p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: color-text-subtle;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;
    margin-right: 8px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}