@value colors: "../../style/colors.css";
@value color-purple, color-purple-dark, color-success, color-error from colors;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.showPassword {
  border: none;
  color: color-purple;
  width: 100px;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.showPassword:hover {
  color: color-purple-dark;
}

.showPassword:focus {
  color: color-purple-light;
}

.icon {
  margin-right: 8px;
}

.required {
  color: color-purple;
}

.input {
  width: 100%;
}

.valid {
  color: color-success;
}

.invalid {
  color: color-error;
}

.hintIcon {
  margin-right: 8px;
}

.passwordHint p {
  font-size: 14px;
  margin: 8px 0;
}

.errorInput {
  border-color: color-error;
}

.errorHint {
  margin-top: 0;
  color: color-error;
  font-size: 12px;
}