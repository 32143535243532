@value colors: "../../../../components/library/style/colors.css";
@value color-success from colors;

.userDescription {
    min-height: calc(100vh - 65px);
    box-sizing: border-box;
    padding: 24px;
    grid-area: main;
    background-color: #F4F4F4;
}

.main {
    background-color: white;
    height: 100%;
}

.title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;

    margin: 0;
}

.header {
    padding: 24px;
    height: 12%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
}

.active {
    color: color-success;
    font-weight: 500;
    margin: 0;
}


.accountState {
    color: color-success;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
}

.accountState p {
    margin: 0;
}

.accountState .dot {
    height: 9px;
    width: 9px;
    border-radius: 9px;
    margin-right: 9px;
    background-color: color-success;
}

.section {
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;
}

.section header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section header h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form > div {
    width: 320px;
}

.buttonGroup {
    display: flex;
}

.buttonGroup button + button {
    margin-left: 24px;
}
