@value colors: "../../../components/library/style/colors.css";
@value color-gray, color-error, color-error-light from colors;

.buttonGroup {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.buttonGroup > button {
    width: 100%;
}

.logIn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.link {
    font-weight: 500;
    text-decoration: none;
    margin-left: 8px;
}

.checkbox a {
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
}

.checkboxContainer {
    margin-top: 16px;
}

.checkboxContainer button {
    border-color: color-gray;
}

.hintError {
    color: color-error;
    font-size: 12px;
}

.errorServer {
    color: color-error;
    background-color: color-error-light;
    padding: 8px;
    border-radius: 5px;
}

.icon {
    margin-right: 8px;
}

.linkSales {
    margin-left: 0;
}

.link {
    margin-left: 8px;
}