@value colors: "../../components/library/style/colors.css";
@value color-gray, color-gray-light, color-purple, color-error, color-error-dark, color-error-light from colors;

.logIn {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.logo {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 }

.row p {
    margin: 0;
}

.label {
    font-weight: 500;
}

.inputWrapper input {
    all: unset;
    width: 90%;
    padding-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.inputWrapper button {
    width: 10%;
}

.inputWrapper {
    border: 1px solid color-gray-light;
    height: 42px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: white;
}

.inputWrapper:hover {
    cursor: pointer;
    border-color: color-purple;
}

.inputWrapper:focus-within {
    border: 1px solid color-purple;
    box-shadow: 0px 0px 6px #b1bdff;
}

.separator {
    color: color-gray;
}

.separator hr {
    width: 40%;
    height: 0;
    border: none;
    border-bottom: 1px solid color-gray-light;
}

.separator p {
    width: 20%;
    text-align: center;
    color: color-gray;
    margin: 0;
}

.buttonGroup button + button {
    margin-top: 8px;
}

.custom {
    font-weight: 400;
    font-size: 14px;
    justify-content: flex-end;
    padding: 0;
    min-width: unset !important;
}

.visibility {
    display: flex;
    align-items: center;
    justify-content: center;
}

.visibility .icon {
    font-size: 16px;
}

.hint {
    margin-top: 4px;
    font-size: 12px;
    color: color-error;
}

.errorInput {
    border-color: color-error;
}

.errorMessage {
    color: color-error;
}

.error {
    color: color-error-dark;
    background-color: #fff1f3;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.error .icon {
    margin-right: 4px;
}

@media screen and (max-width: 500px) {
    .logIn {
        background: none;
        width: 90%;
        margin: auto;
    }
}