@value colors: "../../../../../../components/library/style/colors.css";
@value color-error-light, color-error-dark, color-success-light, color-success-dark from colors;

.error {
    background-color: color-error-light;
    color: color-error-dark;
    padding: 4px 16px;
    border-radius: 5px;
    width: min-content;
    max-width: 400px;
    width: max-content;
    margin: 0;
}

.success {
    background-color: color-success-light;
    color: color-success-dark;
    padding: 4px 16px;
    border-radius: 5px;
    max-width: 400px;
    width: max-content;
    margin: 0;
}