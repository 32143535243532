@value colors: "../../components/library/style/colors.css";
@value color-purple-bg, color-purple-light from colors;

.applications {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;
}

.list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
}

.list button {
    width: 100%;
    height: 86px;
    background-color: color-purple-bg;
    border-radius: 5px;
    transition: .3s;
}

.list button:hover {
    background-color: color-purple-light;
    color: white;
}

.list button + button {
    margin-top: 16px;
}