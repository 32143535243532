@value colors: "../../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray-light, color-gray from colors;

.section {
    height: 88%;
}
.section header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 10%;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
}

.section main {
    height: 80%;
    overflow-y: scroll;
}

.section header h2 {
    font-size: 24px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
}

.buttonGroup button + button {
    margin-left: 16px;
}

.cancel {
    color: color-text-subtle;
    border: 1px solid color-gray;
    transition: .3s;
}

.cancel:hover {
    background-color: color-gray-light;
}

.search {
    height: 10%;
    min-height: 70px;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
}

.icon {
    margin-right: 8px;
    color: color-text-subtle;
}

.search input {
    height: 100%;
    width: 100%;

    border: none;
    background: none;
    outline: none;

    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
}