@value colors: "../../../../../../components/library/style/colors.css";
@value color-purple from colors;

.button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: #F5F7FF;
    padding: 0 16px;

    cursor: pointer;
}

.row, .role {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.role {
    padding: 0 16px;
    justify-content: space-between;
}

.list {
    margin: 0;
}

.icon {
    margin-right: 8px;
}

.title {
    font-weight: 500;
}

.selected {
    color: color-purple;
    margin-left: 8px;
}