@value colors: "../../components/library/style/colors.css";
@value color-error-light, color-error-dark, color-text-subtle, color-purple, color-gray-light from colors;

.section {
    max-width: 1440px;
    margin: auto;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
}

.section p {
    overflow-wrap: anywhere;
}

.title {
    font-weight: 500;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tokenWrapper, .decodedTokenWrapper {
    width: 49%;
}

.token {
    background-color: color-gray-light;
    padding: 24px;
    border-radius: 5px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.copy {
    color: color-text-subtle;
    padding: 0;
    min-width: unset;
}

.copy:hover {
    color: color-purple;
}

.container {
    margin-top: 24px;
}

.error {
    background-color: color-error-light;
    color: color-error-dark;
    padding: 4px;
    border-radius: 5px;
    margin: 0 0 8px 0;

    display: flex;
    align-items: center;
}

.icon {
    margin-right: 4px;
}

.list {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.item {
    min-width: 150px;
    padding: 4px;
    box-sizing: border-box;
}

.item.title {
    font-weight: 500;
    margin-bottom: 0;
}

.item.value {
    margin-top: 4px;
    color: color-text-subtle;
}

.table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 700px;
    overflow-y: scroll;
}