@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray-light, color-success, color-error from colors;

.usersList {
    grid-area: sidebar;
    box-shadow: 4px 0 25px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 65px);
    background-color: white;
}

.inputWrapper {
    border-bottom: 1px solid #E5E5E5;

    height: 75px;
    padding: 0 24px;
    display: flex;
    align-items: center;
}

.searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    color: color-text-subtle;
}

.input {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto, sans-serif;
    color: color-text-subtle;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    height: calc(100% - 75px);
    overflow-y: scroll;
    background-color: white;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
}

.list::-webkit-scrollbar {
    width: 5px;  /* for vertical scrollbars */
}

.list::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.item {
    padding: 0 24px;
    border-bottom: 1px solid #E5E5E5;
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: .3s;
}

.item:hover, .item:focus, .selected {
    background-color: #F4F4F4;
    cursor: pointer;
}

.title {
    font-weight: 500;
    margin: 0;
}

.description {
    color: color-text-subtle;
    margin: 0;
}

.users {
    padding: 8px 24px;
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
}

.icon {
    font-size: 16px !important;
    margin-left: 4px;
}

.icon.green {
    color: color-success;
}

.icon.orange {
    color: darkorange;
}

.icon.red {
    color: color-error;
}
