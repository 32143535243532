body {
  margin: 0;
}

button {
  background: none;
  outline: none;
  margin: 0;
  border: none;
  cursor: pointer;
  color: inherit;
}

a {
  color: black;
  text-decoration: underline;
}
