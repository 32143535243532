@value colors: "../components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

.main {
    width: 100%;
    /* min-height: calc(100vh - 75px); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: color-purple-bg;
    overflow: hidden;
    position: relative;
}

.background {
    position: absolute;
    z-index: 0;
    width: 100%;
    bottom: 0;
}

.content {
    z-index: 10;
}

.footer {
    height: 75px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;

    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav a {
    text-decoration: none;
    color: black;
    transition: .3s;
    font-size: 16px;
}

.nav a:hover {
    color: color-purple;
}

.nav a + a {
    margin-left: 16px;
}

.logo {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.selection {
    border: none;
    background: none;
    font-family: 'Roboto';
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

@media screen and (max-width: 500px) {
    .footer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
    }

    .nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .nav a + a {
        margin-left: 0;
    }

    .nav a {
        margin-bottom: 8px;
    }

    .content {
        width: 100%;
    }
}
