@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-text-subtle, color-gray, color-gray-light from colors;

.navigation {
    grid-area: header;
    width: 100%;
    height: 65px;
    padding: 0 24px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;
    border-bottom: 1px solid #E5E5E5;
}

.navigation p {
    font-size: 24px;
}

.active {
    color: color-purple;
}

.navigation button {
    padding: 0;
    min-width: 0;
}

.navigation p {
    margin-right: 32px;
}

.navigation button {
    margin-right: 32px;
}

.navigation .logOut {
    justify-self: flex-end;
    color: color-text-subtle;
    border: 1px solid color-gray;
    padding: 0 16px;
}

.navigation .logOut:hover {
    background-color: color-gray-light;
    border-color: color-gray-light;
}