@value colors: "../../components/library/style/colors.css";
@value color-gray, color-text-subtle, color-gray-light, color-purple, color-purple-dark, color-error, color-error-dark, color-error-light from colors;

.modal {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 650px;
}

.button {
    background-color: color-purple;
    color: white;
    text-decoration: none;
    font-weight: 500;
    height: 48px;
    width: 100%;
    margin-top: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

.button:hover {
    color: white;
    background-color: color-purple-dark;
}

.modal h1 {
    text-align: center;
    font-size: 32px;
    margin: 0;
}

.modal .description {
    text-align: center;
    font-size: 18px;
    margin-top: 8px;
    color: color-text-subtle;
}

.success {
    margin-top: 32px;
}