@value colors: "../../components/library/style/colors.css";
@value color-warning-light, color-warning-dark, color-text-subtle from colors;

.section {
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.illustration {
    margin-top: 24px;

    width: 100px;
    height: 100px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: color-warning-light;
    color: color-warning-dark;
}

.icon {
    font-size: 48px !important;
}

.section h1 {
    font-size: 24px;
    margin-bottom: 0;
}

.description {
    color: color-text-subtle;
    text-align: center;
}

.section button {
    width: 100%;
}