@value colors: "../../../../../../../components/library/style/colors.css";
@value color-gray-light, color-purple from colors;

.checkbox {
    height: 32px;
    width: 32px;

    background-color: white;
    border: 1px solid color-gray-light;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .3s;
    cursor: pointer;
}

.checkbox.checked, .checkbox:hover {
    color: color-purple;
    border-color: color-purple;
}