@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray-light from colors;

.sidebar {
    grid-area: sidebar;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 65px);
    overflow: hidden;
}

.sidebar header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    padding: 0 24px;
    border-bottom: 1px solid #E5E5E5;
}

.list {
    height: calc(100% - 75px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.sidebar header button {
    margin-left: 8px;
    width: 64px;
    min-width: unset;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar header button span {
    margin: 0;
}

.searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    color: color-text-subtle;
}

.input {
    background: none;
    border: none;
    height: 48px;
    outline: none;
    font-family: Roboto;
    color: color-text-subtle;
    font-weight: 400;
    font-size: 16px;
    padding: 0 8px;
    width: 100%;
}