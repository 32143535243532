@value colors: "../../../../../components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

.selected > .button {
    color: white;
    background-color: color-purple;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 16px;

    cursor: pointer;
    background-color: #F5F7FF;
}

.row, .role {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.role {
    padding: 0 16px;
    border-bottom: 1px solid #E5E5E5;
}

.list {
    margin: 0;
    padding: 0;
}

.icon {
    margin-right: 8px;
    font-size: 18px !important;
}

.title {
    font-weight: 500;
}

.selected {
    color: color-purple;
}

.child {
    padding-left: 8px;
}