@value colors: "../style/colors.css";
@value color-error, color-success-dark from colors;

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    position: absolute;
    top: -35px;
    left: 22.5px;
}

/* Locked */
.lock {
    width: 24px;
    height: 21px;
    border: 3px solid color-error;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.lock:after {
    content: "";
    display: block;
    background: color-error;
    width: 3px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.lock:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    bottom: 100%;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border: 3px solid color-error;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom: 0;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
/* Locked Hover */
.lock:hover:before {
    height: 12px;
}
/* Unlocked */
.unlocked {
    transform: rotate(10deg);
}
.unlocked:before {
    bottom: 130%;
    left: 31%;
    margin-left: -11.5px;
    transform: rotate(-45deg);
}
.unlocked,
.unlocked:before {
    border-color: color-success-dark;
}
.unlocked:after {
    background: color-success-dark;
}
/* Unlocked Hover */
.unlocked:hover {
    transform: rotate(3deg);
}
.unlocked:hover:before {
    height: 10px;
    left: 40%;
    bottom: 124%;
    transform: rotate(-30deg);
}